import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import sort_map from './sort_map'

const BaseFilterSortItemComponent = () => import("@/components/base/filter/BaseFilterSortItemComponent"),
    BaseFilterCategItemComponent = () => import("@/components/base/filter/BaseFilterCategItemComponent"),
    BaseFilterTagItemComponent = () => import("@/components/base/filter/BaseFilterTagItemComponent"),
    BaseFilterPriceItemComponent = () => import("@/components/base/filter/BaseFilterPriceItemComponent")

export default {
    name: "BaseFilterComponent",
    mixins: [BaseRenderMixin],
    methods: {
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, children

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)

            if (props) data.props = props
            if (attrs) data.attrs = attrs

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null

            if (element_data.attr && 'deepsel-filter-sort-item' in element_data.attr) {
                el = BaseFilterSortItemComponent
                props = {
                    component_data: element_data,
                    sort_item: context.sort_item
                }
            }

            if (element_data.attr && 'deepsel-filter-category-item' in element_data.attr) {
                el = BaseFilterCategItemComponent
                props = {
                    component_data: element_data,
                    category: context.category
                }
            }

            if (element_data.attr && 'deepsel-filter-tag-item' in element_data.attr) {
                el = BaseFilterTagItemComponent
                props = {
                    component_data: element_data,
                    tag: context.tag
                }
            }

            if (element_data.attr && 'deepsel-filter-price-item' in element_data.attr) {
                el = BaseFilterPriceItemComponent
                props = {
                    component_data: element_data,
                }
            }

            return [el, props]
        },
        get_children(element_data, createElement) {
            let children = []
            let sort_item_children = false
            let categ_item_children = false
            let tag_item_children = false

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        if (item.attr && 'deepsel-filter-sort-item' in item.attr) {
                            if (sort_item_children) continue
                            sort_item_children = true

                            for (let sort_item of Object.keys(sort_map)) {
                                children.push(this.make_element_tree(createElement, item, {}, {sort_item}))
                            }

                        } else if (item.attr && 'deepsel-filter-category-item' in item.attr) {
                            if (categ_item_children) continue
                            categ_item_children = true

                            for (let category of this.$store.state.common_data.categories) {
                                children.push(this.make_element_tree(createElement, item, {}, {category}))
                            }

                        } else if (item.attr && 'deepsel-filter-tag-item' in item.attr) {
                            if (tag_item_children) continue
                            tag_item_children = true

                            for (let tag of this.$store.state.common_data.product_tags) {
                                children.push(this.make_element_tree(createElement, item, {}, {tag}))
                            }

                        } else {
                            children.push(this.make_element_tree(createElement, item))
                        }
                    } else if (item.node === "text") children.push(item.text)
                }
            }

            return children
        },
    },
}
